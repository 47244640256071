function wordMaskInit() {
  const wordMasks = document.querySelectorAll(".word-mask");

  wordMasks.forEach((wordMask) => {
    Splitting({
      target: wordMask,
      by: "chars",
      key: null,
    });

    const wordTrigger = wordMask.closest("*[data-mask-parent]");
    const chars = wordMask.querySelectorAll(".word .char");

    gsap.to(chars, {
      y: 0,
      stagger: 0.01,
      scrollTrigger: {
        trigger: wordTrigger ? wordTrigger : wordMask,
        markers: false,
        start: wordTrigger ? "top center+=10%" : "top center+=30%",
      },
    });
  });
}
