const body = document.querySelector('body')
const header = document.querySelector('header')
const menu = document.querySelector('.megamenu')
const menuBtn = document.querySelector('.header-button')
const main = document.querySelector('main')
const footer = document.querySelector('footer')

let smBreapoint = window.matchMedia('(min-width: 0px) and (max-width: 767px)')
let mdBreapoint = window.matchMedia('(min-width: 768px)')

// new Vivus('my-svg', { duration: 200 });
window.onload = function () {
  loadingPageInit()
}

function checkMenuOpen() {
  const menuIsOpen = menu.classList.contains('active')

  if (menuIsOpen) {
    body.classList.add('menu-active')
  } else {
    body.classList.remove('menu-active')
  }
}

function clickMenu() {
  menuBtn.addEventListener('click', () => {
    if (menu.classList.contains('active')) {
      body.classList.remove('menu-active')
      menu.classList.remove('active')
    } else {
      body.classList.add('menu-active')
      menu.classList.add('active')
    }
  })
}

function caseSliderInit() {
  const swiperCase = new Swiper('.case-wrapper', {
    spaceBetween: 24,
    slidesPerView: 1.4,
    freeMode: true,

    breakpoints: {
      576: {
        spaceBetween: 16,
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 3,
      },

      992: {
        slidesPerView: 4,
        spaceBetween: 52,
      },
    },

    scrollbar: {
      el: '.slider-scrollbar',
    },

    navigation: {
      prevEl: '.slider-navigation-prev',
      nextEl: '.slider-navigation-next',
      disabledClass: 'disabled',
    },
  })

  return swiperCase
}

function btn_contact_init() {
  /* $window.scroll(function () {
        //SHOW-HIDE BTN CONTACT
        if ($body.attr("data-sezione") != "sect-splash") {
            $("#btn-contact").addClass("active");
        } else {
            $("#btn-contact").removeClass("active");
        }
        //BTN CONTACT
        var fixed = $("#btn-contact");
        var footerbotrect = $(".footer-bot")[0].getBoundingClientRect();
        var footerbottom = footerbotrect.top;
        var fix_footer = windowHeight + $(".footer-top").outerHeight() / 4;
        if (footerbottom <= fix_footer) {
            fixed.addClass("bottom");
            fixed.removeClass("animated");
        }
        if ($("#btn-contact").hasClass("bottom")) {
            if (footerbottom > fix_footer) {
                fixed.removeClass("bottom");
                fixed.addClass("animated");
            }
        }
    });
    $window.scroll(function () {
        $("#btn-contact").removeClass("delay");
    }); */
  const myModalEl = document.getElementById('modal-contatti')

  myModalEl.addEventListener('shown.bs.modal', (event) => {
    myndbot_init()

    var htmlEl = document.querySelector('.g-recaptcha')

    var captchaOptions = {
      sitekey: '6LeHp3UUAAAAAF69K4zvFP5feirekN9aN0zheL63',
      size: 'invisible',
      callback: function (token) {
        data.append('g-recaptcha-response', token)
        var xhr = new XMLHttpRequest()
        xhr.open('POST', 'send/index.php', true)
        xhr.onload = function () {
          console.log(xhr.response)
          $('#convForm').addClass('disablechat')
          $('#userInput').prop('disabled', true)
          setTimeout(function () {
            $('#modal-contatti').modal('hide')
          }, 2000)
        }
        xhr.send(data)
      },
    }

    recaptchaId = window.grecaptcha.render(htmlEl, captchaOptions, false)
  })

  /* $("#modal-contatti").on("shown.bs.modal", function (e) {
        myndbot_init();
        var htmlEl = document.querySelector(".g-recaptcha");

        var captchaOptions = {
            sitekey: "6LeHp3UUAAAAAF69K4zvFP5feirekN9aN0zheL63",
            size: "invisible",
            callback: function (token) {
                data.append("g-recaptcha-response", token);
                var xhr = new XMLHttpRequest();
                xhr.open("POST", "send/index.php", true);
                xhr.onload = function () {
                    console.log(xhr.response);
                    $("#convForm").addClass("disablechat");
                    $("#userInput").prop("disabled", true);
                    setTimeout(function () {
                        $("#modal-contatti").modal("hide");
                    }, 2000);
                };
                xhr.send(data);
            },
        };

        recaptchaId = window.grecaptcha.render(htmlEl, captchaOptions, false);
    });  */

  /* $("#modal-contatti").on("hidden.bs.modal", function (e) {
        $("#chat").remove();
    });  */

  myModalEl.addEventListener('hidden.bs.modal', (event) => {
    $('#chat').remove()
  })
}

function btn_contact_scroll_init() {
  const btn = document.querySelector('#btn-contact')

  if (footer.classList.contains('visible')) {
    btn.classList.add('in-footer')
  } else {
    if (btn.classList.contains('in-footer')) {
      btn.classList.remove('in-footer')
    }
  }
}

window.onScriptLoad = function () {}

data = new FormData()

// myndbot
var rollbackTo = false
var originalState = false
function storeState(stateWrapper) {
  rollbackTo = stateWrapper.current
}
function rollback(stateWrapper) {
  if (rollbackTo != false) {
    if (originalState == false) {
      originalState = stateWrapper.current.next
    }
    stateWrapper.current.next = rollbackTo
  }
}
var myndbot_init = function () {
  $('#modal-contatti .modal-body').append($('#myndbot_cont').html())
  var placeholderinput = $('#inputplaceholder').html()
  var convForm = $('#chat').convform({
    placeHolder: placeholderinput,
    typeInputUi: 'textarea',
    eventList: {
      onSubmitForm: function (convState) {
        if (convState.answers['tipoprivacy']['value'] != 'privacysi') {
          $('#convForm').addClass('disablechat')
          $('#userInput').prop('disabled', true)
          setTimeout(function () {
            $('#modal-contatti').modal('hide')
          }, 2000)
        } else {
          for (var key in convState.answers) {
            data.append(key, convState.answers[key]['value'])
          }
          window.grecaptcha.execute(recaptchaId)
          token = window.grecaptcha.getResponse(recaptchaId)
        }
      },
    },
  })
}

function imageMobileParallax() {
  var imageMobile = document.getElementsByClassName(
    'fixed-parallax-video-mobile',
  )
  new simpleParallax(imageMobile, {
    scale: 1.1,
  })
}

function openContactUsOffcanvasWhenUrlIsSpecific() {
  const contactForm = new bootstrap.Offcanvas('#offcanvasContactUs')
  
  if(window.location.href.includes('?show-contact-form=true')) {
    contactForm.show()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  openContactUsOffcanvasWhenUrlIsSpecific()

  btn_contact_init()

  // animationPage();
  checkMenuOpen()
  clickMenu()
  hpVideoControlsInit()
  parallaxImage()

  innovationInit()
  caseSliderInit()
  teamSliderInit()
  vantagesSliderInit()
  lineDrawing()

  fixedParallaxVideoInit()
  cardAnimation()

  scrollFixed()
  scrollBlurInit()

  cardOverlay()

  wordMaskInit()
  footerMtopInit() // TODO: Sistemare
  AOS.init()

  maskImageInit()
  hrAnimation()
  workListInit()
  cardFadeAnimation()
  gridImageCarousel()

  imageMobileParallax()

  if (window.innerWidth <= 992) {
    sectionBrand.sectionBrandPhone()
  }

  if (window.innerWidth >= 992) {
    sectionBrand.sectionBrandTablet()
  }

  accordionEmptyStateInit() // ! da sistemare
})

window.addEventListener('resize', (event) => {
  footerMtopInit()
  gridImageCarousel()

  if (window.innerWidth <= 992) {
    sectionBrand.sectionBrandPhone()
  }

  if (window.innerWidth >= 992) {
    sectionBrand.sectionBrandTablet()
  }
})

window.addEventListener('scroll', (event) => {
  btn_contact_scroll_init()
})
