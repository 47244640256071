function innovationInit() {
  const innovation = document.querySelector('.section-innovation')

  if (innovation) {
    const innovationContainer = innovation.querySelector(
      '.container.container-skinny',
    )
    const innovationBtn = innovation.querySelector(
      '.btn.btn-innovation',
    )
    let innovationHeight = innovation.offsetHeight

    if (smBreapoint.matches) {
      innovationHeight += innovationHeight * 1
      pauseDuration = 0.5
    } else if (mdBreapoint.matches) {
      innovationHeight += innovationHeight * 4
      pauseDuration = 2
    }

    const innovationTitle1 = document.querySelector('.innovation-title-1')
    const innovationTitle2 = document.querySelector('.innovation-title-2')
    const innovationTitle3 = document.querySelector('.innovation-title-3')

    gsap
      .timeline({
        onUpdate: () => console.log('update'),
        scrollTrigger: {
          // normalizeScroll: true,
          trigger: innovation,
          pin: true,
          //pinSpacing: true,
          //markers: true,
          start: 'top top',
          end: `+=${innovationHeight} bottom`,
          scrub: 1,
        },
      })
      .set(innovationBtn, { color: '#fff', borderColor: '#fff'  })

      .set(innovation, { backgroundColor: '#fff', color: '#000' })

      .set(innovationTitle1, {opacity: '1'})
      .set(innovationTitle2, {opacity: '0'})
      .set(innovationTitle3, {opacity: '0'})
      


      
      .to({}, { duration: pauseDuration })
      // .to(innovationBtn, { color: '#000', borderColor: '#000' }, '<')
      
      
      // Step 1
      .set(innovationBtn, { color: '#000', borderColor: '#000'  }, '>')
      .to('.text-collapse-1', { yPercent: -100 })
      .to(innovation, { backgroundColor: '#000', color: '#fff',  }, '<')
      
      .to(innovationTitle1, {opacity: '0'}, '<')
      .to(innovationTitle2, {opacity: '1'}, '<')
      .to(innovationTitle3, {opacity: '0'}, '<')

      // Step 2
      //.set(innovationBtn, { className: 'reverse'  }, '<')
      .set(innovationBtn, { className: 'btn btn-innovation'  }, '<')
      // .set('.btn-inverted', { className: 'btn-inverted' }, '<')
      .from('.text-collapse-2', { yPercent: 100 }, '<')
      .to({}, { duration: pauseDuration })
      .to('.text-collapse-2', { yPercent: -100 })
      .to({}, { duration: pauseDuration })

      
      
      .to(innovationTitle1, {opacity: '0'}, '<')
      .to(innovationTitle2, {opacity: '0'}, '<')
      .to(innovationTitle3, {opacity: '1'}, '<')
      
      // Step 3
      // .to(innovationBtn, { color: '#fff', borderColor: '#fff' }, '<')
      // .set('.btn-inverted', { className: 'btn-inverted active' }, '<')
      .from('.text-collapse-3', { yPercent: 100 }, '<')
      .to(innovation, { backgroundColor: '#d50058', color: '#fff' }, '<')
      //.set('.btn-inverted', { className: 'btn-inverted' }, '>')
      .to({}, { duration: pauseDuration })

      
  }
}


